import React from 'react';


import './Bathrooms.css'

function GeneralContracting() {
    return (
        <main className={'Bathrooms'}>
            <section className={'information'}>
                <h2>GENERAL CONTRACTING</h2>
                <div className={'line'}/>
                <p style={{textAlign:"center"}}>Tiling Company offers general contracting services. No matter how big or small, we can help you with any remodeling project. Our network of quality contractors work together. And all delicate types of processing are performed by our craftsmen.
                </p>
                <p className={'bold'}>Our wide range of General Contracting Services includes:
                </p>
                <h4>TILING COMPANY specializes in Kitchen and Bathroom Remodeling.
                </h4>
                <p>We offer a wide range of remodeling services, including:</p>
                <ui>
                    <li>Kitchen & Bathroom Remodeling</li>
                    <li>Basement Finishing</li>
                    <li>Flooring Installation</li>
                    <li>Drywall Installation and Repair</li>


                </ui>
                {/*<h4>text text text text text text text text text text </h4>*/}
                <p>No matter what type of remodeling project you are planning, we can help with the experience and knowledge to get the job done right. We always strive to exceed our customers' expectations and are always available to answer any questions you may have. If you are looking for a reliable and experienced handyman for your house, contact <span>TILING COMPANY</span> today. We would be happy to discuss your needs and provide a <span>FREE</span> estimate.
                </p>

                {/*<h4>text text text text text text text text text text
                </h4>*/}
                <p className={'bold'}>We'll work with you to create a plan that fits your needs and budget. We look forward to helping you achieve your home improvement goals!
                </p>
                <h2>ADDITION</h2>
                <div className={'line'}/>
                <p>This is a company worth calling. We provide a full range of kitchen and bathroom renovation services with many years of experience in the construction industry. We understand the challenges of home renovation, so we'll work with you every step of the way to make the process as easy as possible.
                </p>
{/*                <p>Our experienced team will help you design and create the perfect addition to your home. Our extensive experience in kitchens and bathrooms makes it the perfect solution for expanding your kitchen. We will work with you to make your home stylish and functional. From start to finish, we will manage the project and take care of all the details so you can relax and enjoy your new space.</p>*/}

                <h4>Home additions:</h4>
                <p>If you are planning to renovate your home, make sure you choose the right craftsman. TILING COMPANY is a second-generation family company specializing in the repair of your home. We have completed numerous projects in <span>CHICAGO IL</span> and are committed to providing our clients with quality workmanship and excellent customer service. We can renovate your existing home or a home you've just purchased.</p>
            </section>
        </main>
    )
        ;
}

export default GeneralContracting;
